<template>
  <v-container fluid>
    <layout :back="true" />
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h3 class="font-weight-black">{{ $t("profile.edit") }}</h3>
          </v-col>
        </v-row>
        <!-- Candidate base data-->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              {{ $t("field.full_name") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <v-text-field
              :label="$t('field.full_name')"
              hide-details="auto"
              :rules="required"
              outlined
              v-model="candidate.full_name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.phone_number") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="candidate.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateCountry="updateCountry"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.address") }}
              <span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <vuetify-google-autocomplete
              ref="address"
              id="map"
              @change="validateAddress('address', $event)"
              classname="form-control"
              :label="$t('field.address_placeholder')"
              placeholder=""
              :rules="address_validator"
              outlined
              :value="candidate.address.formatted"
              :hide-details="false"
              v-on:placechanged="getAddressData"
              country="es"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.birthday") }}
              <span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-text-field
              name="input-10-1"
              :label="$t('field.birthday')"
              validate-on-blur
              :rules="birthday_date"
              hide-details="auto"
              outlined
              v-model="candidate.formatDate"
              v-mask="'##/##/####'"
              placeholder="DD/MM/YYYY"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Candidate titles -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.title") }}
              <span class="red--text text-subtitle-2">*</span></v-subheader
            ><v-select
              :items="titles"
              v-model="candidate.title"
              hide-details="auto"
              :label="$t('field.title')"
              item-text="name"
              :rules="required"
              item-value="id"
              outlined
              disabled
            ></v-select>
          </v-col>
          <!-- <v-col cols="6" sm="2" md="1" class="pa-0 ma-0">
          <v-subheader class="pa-0 ma-0 text-caption primary--text"
            ><span class="red--text text-subtitle-2">*</span
            >{{ $t("field.work_years") }}</v-subheader
          >
          <v-text-field
            v-model="candidate.experience_time"
            name="input-10-1"
            :label="$t('field.years')"
            hide-details="auto"
            :rules="required"
            inputmode="numeric"
            outlined
          >
          </v-text-field>
        </v-col> -->
        </v-row>
        <!-- Candidate Data when is a pharmacist -->
        <div v-if="candidate.title == pharmacistTitle">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 text-caption primary--text"
                >{{ $t("field.has_license") }}
                <span class="red--text text-subtitle-2">*</span></v-subheader
              >
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-radio-group
                v-model="candidate.profile.has_license"
                row
                :rules="required_boolean"
                class="ma-0"
              >
                <v-radio :value="true">
                  <template v-slot:label>
                    <div
                      class="primary--text text-subtitle-1 font-weight-normal"
                    >
                      {{ $t("field.yes") }}
                    </div>
                  </template></v-radio
                >
                <v-radio :value="false" @click="candidate.profile.license = ''">
                  <template v-slot:label>
                    <div
                      class="primary--text text-subtitle-1 font-weight-normal"
                    >
                      {{ $t("field.no") }}
                    </div>
                  </template></v-radio
                >
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="candidate.profile.has_license"
            class="pa-0 ma-0"
            justify-sm="center"
          >
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.license") }}
                <span class="red--text text-subtitle-2">*</span></v-subheader
              ><v-text-field
                v-model="candidate.profile.license"
                :label="$t('field.license')"
                maxlength="9"
                :rules="candidate.profile.has_license ? required : []"
                validate-on-blur
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="candidate.profile.has_license"
            class="pa-0 ma-0"
            justify-sm="center"
          >
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.license_state") }}
                <span class="red--text text-subtitle-2">*</span></v-subheader
              ><v-autocomplete
                :items="states"
                item-value="id"
                item-text="name"
                validate-on-blur
                :rules="candidate.profile.has_license ? required : []"
                v-model="candidate.profile.license_state"
                hide-details="auto"
                :label="$t('field.license_state')"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.university")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              ><v-select
                :items="universities"
                item-value="id"
                item-text="name"
                validate-on-blur
                :rules="required_boolean"
                v-model="candidate.profile.university"
                hide-details="auto"
                :label="$t('field.university')"
                outlined
                disabled
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="candidate.profile && candidate.profile.university.id === otherUniversity"
            class="pa-0 ma-0"
            justify-sm="center"
          >
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.homologated_status") }}
                <span class="red--text text-subtitle-2">*</span></v-subheader
              ><v-select
                :items="homologatedChoices"
                item-value="[0]"
                item-text="[1]"
                validate-on-blur
                :rules="candidate.profile.has_license ? required : []"
                v-model="candidate.profile.homologated_status"
                hide-details="auto"
                :label="$t('field.homologated_status')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.experience")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              ><v-select
                :items="pharmacyExperienceYears"
                item-value="[0]"
                item-text="[1]"
                validate-on-blur
                :rules="required_boolean"
                v-model="candidate.profile.experience"
                hide-details="auto"
                :label="$t('field.experience')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div v-for="(e, index) in candidate.educations" :key="index">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.other_title") }}
                <span class="black--text pa-1">{{
                  $t("validation.opt")
                }}</span> </v-subheader
              ><v-text-field
                v-model="e.title"
                name="input-10-1"
                :label="$t('field.other_title')"
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0 mt-2" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <a @click="appendStudies" class="text-subtitle-1"
              >+ {{ $t("title.add") }}
            </a>
          </v-col>
        </v-row>
        <!-- Candidate specializations -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.specialization") }}
              <span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            ><v-select
              :items="expertises"
              item-value="id"
              multiple
              item-text="name"
              v-model="candidate.expertises"
              hide-details="auto"
              :label="$t('field.specialization')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Candidate experiencie area -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.experience_fields") }}
              <span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            ><v-select
              :items="experienceFields"
              item-value="id"
              multiple
              item-text="name"
              v-model="candidate.experience_fields"
              hide-details="auto"
              :label="$t('field.experience_fields')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Candidate languages -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.languages_candidate") }}
              <span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            ><v-select
              :items="languages"
              multiple
              return-object
              item-text="name"
              v-model="candidate.candidate_languages"
              hide-details="auto"
              :label="$t('field.languages_candidate')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Working Days -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("offer.working_type")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              v-model="workingDaysSelected"
              :items="workingDays"
              multiple
              item-value="id"
              item-text="name"
              hide-details="auto"
              :rules="required_select"
              :label="$t('offer.working_type')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- Working Days -->
        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">{{
              $t("register.computer_programs")
            }}<div class="black--text font-weight-light pa-1">
                {{ $t("validation.opt") }}
              </div></v-subheader>
            <v-select
              v-model="candidate.computer_programs"
              :items="
                this.computerPrograms.concat(
                  this.candidateData.computer_programs
                )
              "
              item-value="id"
              multiple
              item-text="name"
              hide-details="auto"
              :label="$t('register.type_of_programs')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <div
          v-for="(e, index) in candidate.othersComputerPrograms"
          :key="index + 'i'"
        >
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text">
                {{ $t("register.others_computer_programs") }}
                <div class="black--text font-weight-light pa-1">
                  {{ $t("validation.opt") }}
                </div> </v-subheader
              ><v-text-field
                v-model="e.programs"
                item-value="id"
                name="input-10-1"
                :label="$t('register.others')"
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0 mt-2" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <a @click="appendComputerPrograms" class="text-subtitle-1"
              >+ {{ $t("register.add_another") }}</a
            >
          </v-col>
        </v-row>
        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox v-model="candidate.is_only_state">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("offer.search_only_province") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox v-model="candidate.active_search">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("offer.active_search") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-row class="mx-2 my-8" justify="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-center">
        <div>
          <v-btn
            color="transparent"
            large
            rounded
            class="customButton primary--text"
            @click="$router.go(-1)"
          >
            {{ $t("offer.cancel") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 text-center">
        <div v-if="$refs.form">
          <v-btn
            color="secondary"
            large
            rounded
            @click="updateCandidate"
            :disabled="!formValid"
          >
            {{ $t("operation.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <delete-user></delete-user>
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import EditHelper from "@/helpers/EditProfile.js";
import AddressDataHelper from "@/helpers/AddressData.js";
import VueTelInput from "@/components/shared/VueTelInput.vue";
import DeleteUser from "@/components/shared/DeleteUser.vue";
import { mapActions, mapGetters } from "vuex";
import config from "@/config";

export default {
  components: {
    Layout,
    VuetifyGoogleAutocomplete,
    VueTelInput,
    DeleteUser,
  },
  data() {
    return {
      requiredDefault: true,
      country: null,
      formValid: false,
      workingDaysSelected: [],
      candidate: {
        full_name: null,
        phone_number: null,
        birthday_date: null,
        formatDate: null,
        address: {},
        title: null,
        experience_time: null,
        educations: [],
        othersComputerPrograms: [],
        profile: [],
        expertises: null,
        experience_fields: null,
        candidate_languages: null,
        id: null,
        is_only_state: null,
        computer_programs: [],
        working_day: [],
        active_search: false
      },
      country_code: "",
      phone_number: null,
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      required_boolean: [
        (v) => v != null || this.$i18n.t("validation.required"),
      ],
      required_select: [
        (v) => (v && v.length > 0) || this.$i18n.t("validation.required"),
      ],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.phone_number"),
      ],
      birthday_date: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/.test(
            v
          ) || this.$i18n.t("validation.birthday_date"),
        (v) => this.age(v) >= 18 || this.$i18n.t("validation.adult"),
      ],
      address_validator: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) => {
          return (
            (v &&
              this.candidate.address.latitude &&
              this.candidate.address.latitude != 0) ||
            this.$i18n.t("validation.address")
          );
        },
      ],
      code: [
        {
          name: "España (+34)",
          icon: "",
          code: "+34 ",
        },
      ],
    };
  },
  async created() {
    this.workingDaysSelected = this.candidateData.working_day;
    this.enableLoading();
    this.getWorkingDays();
    await this.getCandidate(this.childId);
    this.getPharmacyExperienceYears();
    this.getPositions();
    this.getUniversities();
    this.getExpertises();
    this.getExperienceFields();
    this.getLanguages();
    this.appendStudies();
    this.formatData();
    this.getTitles();
    this.getHomologatedChoices();
    await this.getStates();
    this.disableLoading();
    this.getComputerPrograms();

  },

  computed: {
    ...mapGetters({ positions: "core/positions" }),
    ...mapGetters({ titles: "core/titles" }),
    ...mapGetters({ expertises: "core/expertises" }),
    ...mapGetters({ experienceFields: "core/experienceFields" }),
    ...mapGetters({ languages: "core/languages" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ candidateData: "candidate/candidate" }),
    ...mapGetters({
      pharmacyExperienceYears: "core/pharmacyExperienceYears",
    }),
    ...mapGetters({ states: "core/states" }),
    ...mapGetters({ universities: "core/universities" }),
    ...mapGetters({ homologatedChoices: "core/homologatedChoices" }),
    ...mapGetters({ workingDays: "core/workingDays" }),
    ...mapGetters({ computerPrograms: "core/computerPrograms" }),

    pharmacistTitle() {
      return config.CONSTANTS.PHARMACIST_TITLE;
    },
    otherUniversity() {
      return config.CONSTANTS.OTHER_UNIVERSITY;
    },
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ getWorkingDays: "core/getWorkingDays" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getPositions: "core/getPositions" }),
    ...mapActions({ getTitles: "core/getTitles" }),
    ...mapActions({ getExpertises: "core/getExpertises" }),
    ...mapActions({ getExperienceFields: "core/getExperienceFields" }),
    ...mapActions({ getLanguages: "core/getLanguages" }),
    ...mapActions({ getCandidate: "candidate/getCandidateById" }),
    ...mapActions({ putCandidate: "candidate/updateCandidate" }),
    ...mapActions({
      getPharmacyExperienceYears: "core/getPharmacyExperienceYears",
    }),
    ...mapActions({ getComputerPrograms: "core/getComputerPrograms" }),

    ...mapActions({ getStates: "core/getStates" }),
    ...mapActions({ getUniversities: "core/getUniversities" }),
    ...mapActions({ getHomologatedChoices: "core/getHomologatedChoices" }),
    validateAddress(address, formatted) {
      if (
        this.candidate[address].formatted != formatted &&
        this.candidate[address].latitude
      ) {
        this.candidate[address] = { formatted: formatted };
      }
    },
    updatePhoneNumber(phone) {
      this.candidate.phone_number = phone;
    },
    updateCountry(country) {
      this.country = "+" + country;
    },
    redirectLogin() {
      this.$router.push({ name: "login" });
    },
    appendStudies() {
      this.candidate.educations.push({
        title: "",
      });
    },
    appendComputerPrograms() {
      this.candidate.othersComputerPrograms.push({
        programs: "",
      });
    },
    async formatData() {
      this.country_code = this.candidateData.phone_number.substr(
        0,
        this.candidateData.phone_number.length - 9
      );
      this.country_code = this.country_code.concat(" ");
      this.phone_number = this.candidateData.phone_number.substr(
        this.candidateData.phone_number.length - 9
      );
      this.candidate.formatDate = this.$moment(
        this.candidateData.birthday_date
      ).format("DD/MM/YYYY");
      Object.assign(
        this.candidate,
        EditHelper.RefactorCandidateData(this.candidateData)
      );
    },
    age(date) {
      return this.$moment(this.$moment(), "DD/MM/YYYY").diff(
        this.$moment(date, "DD/MM/YYYY"),
        "years"
      );
    },
    getAddressData(addressData, placeResultData) {
      this.candidate.address = AddressDataHelper.addressData(
        addressData,
        placeResultData
      );
    },
    formatDate(date) {
      return date.split("/").reverse().join("-");
    },
    async updateCandidate() {
      if (!this.$refs.form.validate()) return;
      if (typeof this.candidate.profile.license_state === 'object' && this.candidate.profile.license_state !== null) {
        this.candidate.profile.license_state = this.candidate.profile.license_state.id;
      }
      this.candidate.birthday_date = this.formatDate(this.candidate.formatDate);
      if (this.candidate.title != this.pharmacistTitle) {
        this.candidate.profile = {};
      }
      await this.formatTelephone();
      EditHelper.RefactorSendCandidate(this.candidate, this.languages);
      this.enableLoading();
      try {
        this.candidate.working_day = this.workingDaysSelected;
        this.candidate.working_day.filter(Boolean)
        await this.putCandidate(this.candidate);
        this.$notify({
          title: this.$i18n.t("notification.profile_update"),
          text: this.$i18n.t("notification.profile_update"),
          type: "success",
        });
        this.$router.push({ name: "profile" });
      } catch (error) {
        Object.values(error && error.response.data).forEach((elm) => {
          this.$notify({
            title: this.$i18n.tc("notification.profile_update_error", 1),
            text: elm.join(","),
            type: "error",
          });
        });
      } finally {
        this.disableLoading();
      }
    },
    formatTelephone() {
      let phone = this.candidate.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.candidate.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.candidate.phone_number = country + " " + phone2;
      } else {
        let tel = this.country + this.candidate.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.candidate.phone_number = country + " " + phone2;
      }
    },
  },
};
</script>
