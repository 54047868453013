import _ from "lodash";
export default {
  RefactorCandidateData(candidate) {
    var candidateData = {
      full_name: candidate.full_name,
      phone_number: candidate.phone_number,
      address: candidate.address,
      birthday_date: candidate.birthday_date,
      title: candidate.title.id,
      id: candidate.id,
      gender: candidate.gender.id,
      user: candidate.user.id,
      is_only_state: candidate.is_only_state,
      experience_time: candidate.experience_time,
      active_search: candidate.active_search,
      educations: JSON.parse(JSON.stringify(candidate.educations)),
      expertises: this.refactorId(candidate.expertises),
      experience_fields: this.refactorId(candidate.experience_fields),
      working_day: this.refactorId(candidate.working_day),
      computer_programs: this.refactorId(candidate.computer_programs),
      candidate_languages: this.refactorLanguage(candidate.languages),
      profile: candidate.profile != null ? _.cloneDeep(candidate.profile) : {}
    };
    return candidateData;
  },
  refactorLanguage(languages) {
    var data = [];
    languages.map(l => {
      data.push(l.language);
    });
    return data;
  },
  refactorId(arrayData) {
    var result = [];
    arrayData.map(p => {
      result.push(p.id);
    });
    return result;
  },
  RefactorSendCandidate(candidate) {
    if (candidate.educations != null) {
      candidate.educations.map(e => {
        e.candidate = candidate.id;
      });
    }
    if (candidate.candidate_languages != null) {
      candidate.candidate_languages.map(l => {
        l.candidate = candidate.id;
        l.language = l.id;
      });
    }
  }
};
