<template>
  <v-container>
    <layout :back="true" />
    <v-row class="mx-2 pa-0 ma-0" v-if="userType != undefined && candidateUserType" justify-sm="center">
      <v-col class="ma-0 pa-0">
        <candidate-edit />
      </v-col>
    </v-row>
    <v-row
      class="mx-2 pa-0 ma-0"
      v-else-if="userType != undefined && pharmacyUserType"
      justify-sm="center"
    >
      <v-col class="ma-0 pa-0">
        <pharmacy-edit />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
//layout
import Layout from "@/components/layouts/Profile.vue";
// Edit components
import CandidateEdit from "@/components/profiles/CandidateEdit.vue";
import PharmacyEdit from "@/components/profiles/PharmacyEdit.vue";
// Configuration
import config from "@/config";
import { mapGetters } from "vuex";
export default {
  components: {
    Layout,
    CandidateEdit,
    PharmacyEdit
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ userType: "user/type" }),
    candidateUserType() {
      if (this.userType != undefined) {
        return (
          config.CONSTANTS.CANDIDATE_USER_TYPE == this.userType.toUpperCase()
        );
      }
      return false;
    },
    pharmacyUserType() {
      return (
        config.CONSTANTS.PHARMACY_USER_TYPE == this.userType.toUpperCase() ||
        config.CONSTANTS.PHARMACY_GROUP_USER_TYPE == this.userType.toUpperCase()
      );
    }
  }
};
</script>
