<template>
  <v-container fluid>
    <layout :back="true" />
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h3 class="font-weight-black">{{ $t("profile.edit") }}</h3>
          </v-col>
        </v-row>
        <!-- pharmacy base data-->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.web")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-text-field
              :label="`${$t('field.web')} (http://)`"
              hide-details="auto"
              outlined
              v-model="pharmacy.web"
              validate-on-blur
              :rules="web_page"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.employee_number")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-select
              :items="pharmacyWorkerNumber"
              v-model="pharmacy.employees_number"
              hide-details="auto"
              validate-on-blur
              :label="$t('field.employee_number')"
              :rules="required"
              item-text="[1]"
              item-value="[0]"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.pharmacist_name")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-text-field
              :label="$t('field.full_name')"
              v-model="pharmacy.pharmacy_owner.full_name"
              hide-details="auto"
              outlined
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.contact_phone_number") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacy.pharmacy_owner.phone_number"
              @updatePhoneNumber="updateOwnerPhoneNumber"
              @updateCountry="updatecountryOwnerTelephone"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox class="mt-6" v-model="contact">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("contact.add") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-if="contact">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.contact_name")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              >
              <v-text-field
                :label="$t('field.contact_name')"
                v-model="pharmacy.contacts[0].full_name"
                hide-details="auto"
                outlined
                :rules="contact ? required : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text">
                {{ $t("field.contact_phone_number")
                }}<span class="red--text text-subtitle-2">*</span>
              </v-subheader>
              <vue-tel-input
                :requiredPass="contact"
                :phoneNumber="pharmacy.contacts[0].phone_number"
                @updatePhoneNumber="updateContactPhoneNumber"
                @updateCountry="updatecountryContactTelephone"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
              <v-subheader class="pa-0 ma-0 text-caption primary--text"
                >{{ $t("field.contact_email") }}
                <span class="red--text text-subtitle-2">*</span></v-subheader
              >
              <v-text-field
                v-model="pharmacy.contacts[0].email"
                :label="$t('field.contact_email')"
                hide-details="auto"
                outlined
                :rules="contact ? required : []"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <h3 class="mt-4 font-weight-black">
              {{ $t("register.pharmacy_data") }}
            </h3>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.commercial_name")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-text-field
              v-model="pharmacy.commercial_name"
              name="input-10-1"
              :label="$t('field.commercial_name')"
              hide-details="auto"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.cif")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <v-text-field
              :label="$t('field.cif')"
              v-model="pharmacy.cif"
              :rules="cif"
              validate-on-blur
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("field.pharmacy_number")
              }}<span class="black--text pa-1">{{
                $t("validation.opt")
              }}</span></v-subheader
            >
            <v-text-field
              v-model="pharmacy.number"
              :label="$t('field.pharmacy_number')"
              color="primary"
              hide-details="auto"
              v-mask="'#########'"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text">
              <!-- <span class="red--text text-subtitle-2">*</span> -->
              {{ $t("field.soe")
              }}<span class="black--text pa-1">{{ $t("validation.opt") }}</span>
            </v-subheader>
            <v-text-field
              v-model="pharmacy.soe"
              :label="$t('field.soe')"
              color="primary"
              hide-details="auto"
              v-mask="'#########'"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="!others_programs">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("register.computer_programs") }}
                 <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <v-select
              v-model="pharmacy.computer_programs"
              :items="computerPrograms"
              item-value="id"
              item-text="name"
              hide-details="auto"
                   :rules="required"
              :label="$t('register.type_of_programs')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox
              class="mt-6"
              v-model="others_programs"
              @click="others_programs == !others_programs"
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("register.others_computer_programs") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="others_programs">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              >{{ $t("register.add_another") }}
                <span class="red--text text-subtitle-2">*</span> </v-subheader
            ><v-text-field
              v-model="pharmacy.others_computer_programs"
              name="input-10-1"
              :label="$t('register.others')"
              hide-details="auto"
              :rules="required"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Computer programs -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text">
              {{ $t("field.phone_number") }}
              <span class="red--text text-subtitle-2">*</span>
            </v-subheader>
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacy.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateCountry="updatecountryPharmacyTelephone"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              >{{ $t("field.address")
              }}<span class="red--text text-subtitle-2">*</span></v-subheader
            >
            <vuetify-google-autocomplete
              ref="address1"
              id="map2"
              classname="form-control"
              :label="$t('field.address_placeholder')"
              outlined
              placeholder=""
              :value="pharmacy.address.formatted"
              :rules="address_validator1"
              @change="validateAddress('address', $event)"
              :hide-details="false"
              country="es"
              v-on:placechanged="getAddressData"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mb-2" justify-sm="center">
          <v-col cols="10" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <span class="text-subtitle-1"
              >{{ $t("field.opening_hours")
              }}<span class="red--text text-subtitle-2">*</span></span
            >
          </v-col>
        </v-row>
        <v-row
          class="pa-0 ma-0"
          justify-sm="center"
          v-for="(hours, index) in openHours"
          :key="index"
        >
          <v-col cols="6" sm="4" md="2" class="pa-0 ml-2 text-h6">
            <h5 class="p-2 mb-1 tertiary--text">- {{ hours.name }}</h5>
          </v-col>
          <v-col cols="5" sm="4" md="2" class="pa-0 ml-2 text-h6">
            <v-switch
              class="pa-0 ma-0 mt-1"
              hide-details="auto"
              dense
              color="success"
              v-model="pharmacy.open_hour"
              :value="hours.id"
              name="horario"
              :rules="required"
            >
              <template v-slot:label>
                <div class="primary--text text-subtitle-1 font-weight-normal">
                  {{
                    pharmacy.open_hour == hours.id
                      ? $t("operation.open")
                      : $t("operation.close")
                  }}
                </div>
              </template></v-switch
            >
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-checkbox class="mt-6" v-model="pharmacy.billing_match_data">
              <template v-slot:label>
                <div class="primary--text text-subtitle-1">
                  {{ $t("field.pharmacy_match_billing") }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <div v-if="!pharmacy.billing_match_data">
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                >{{ $t("field.fiscal_name")
                }}<span class="black--text pa-1">{{
                  $t("validation.opt")
                }}</span></v-subheader
              >
              <v-text-field
                name="input-10-1"
                v-model="pharmacy.fiscal_name"
                :label="$t('field.fiscal_name')"
                hide-details="auto"
                :rules="required"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0" justify-sm="center">
            <v-col cols="12" sm="8" md="4" class="pa-0">
              <v-subheader class="pa-0 text-caption primary--text"
                >{{ $t("field.fiscal_address")
                }}<span class="red--text text-subtitle-2">*</span></v-subheader
              >
              <vuetify-google-autocomplete
                ref="address"
                :id="'map3'"
                placeholder=""
                classname="form-control"
                :value="pharmacy.billing_address.formatted"
                :label="$t('field.address_placeholder')"
                @change="validateAddress('billing_address', $event)"
                :rules="address_validator2"
                outlined
                :hide-details="false"
                v-on:placechanged="getBillingAddressData"
                country="es"
              >
              </vuetify-google-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>
    <v-row class="mx-2 my-8" justify="center">
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-center">
        <div>
          <v-btn
            color="transparent"
            large
            rounded
            class="customButton primary--text"
            @click="$router.go(-1)"
          >
            {{ $t("offer.cancel") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 text-center">
        <div>
          <v-btn
            color="secondary"
            large
            rounded
            @click="updatePharmacy"
            :disabled="!formValid"
          >
            {{ $t("operation.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <delete-user v-if="userType == 'pharmacy'"></delete-user>
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import AddressDataHelper from "@/helpers/AddressData.js";
import { mapActions, mapGetters } from "vuex";
import VueTelInput from "@/components/shared/VueTelInput.vue";
import _ from "lodash";
import DeleteUser from "../shared/DeleteUser.vue";

export default {
  components: {
    Layout,
    VuetifyGoogleAutocomplete,
    VueTelInput,
    DeleteUser,
  },
  data() {
    return {
      requiredDefault: true,
      countryOwnerTelephone: null,
      countryContactTelephone: null,
      countryPharmacyTelephone: null,
      formValid: false,
      others_programs: false,
      myPhone: "",
      phone2: {
        number: "",
        valid: false,
        country: undefined,
      },
      pharmacy: {
        open_hour: null,
        number: null,
        address: {},
        commercial_name: null,
        fiscal_name: null,
        phone_number: null,
        web: null,
        id: null,
        employees_number: null,
        billing_match_data: false,
        billing_address: {},
        nif: null,
        soe: null,
        others_computer_programs: null,
        computer_programs: null,

        pharmacy_owner: {
          full_name: null,
          phone_number: null,
        },
        contacts: [],
      },
      country_code: null,
      pharmacyEx: {
        number: "",
        valid: false,
        country: undefined,
      },
      phone_number: null,
      contact: false,
      pharmacy_phone_number: null,
      pharmacy_code: null,
      contact_phone_number: null,
      contact_code: null,
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.phone_number"),
      ],
      cif: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$)|(^\d{8}[a-zA-Z]{1}$)/.test(v) ||
          this.$i18n.t("validation.cif"),
      ],
      address_validator1: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          (v &&
            this.pharmacy.address.latitude &&
            this.pharmacy.address.latitude != 0) ||
          this.$i18n.t("validation.address"),
      ],
      address_validator2: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          (v &&
            this.pharmacy.billing_address.latitude &&
            this.pharmacy.billing_address.latitude != 0) ||
          this.$i18n.t("validation.address"),
      ],
      web_page: [
        (v) =>
          !v ||
          /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
            v
          ) ||
          this.$i18n.t("validation.web_page"),
      ],
      code: [
        {
          name: "España (+34)",
          icon: "",
          code: "+34 ",
        },
      ],
    };
  },
  async created() {
    this.enableLoading();
    this.getOpenHours();
    this.getPharmacyWorkerNumber();
    await this.getPharmacy(this.childId);
    this.formatData();
    this.disableLoading();
    //this.formatPhone()
    this.getComputerPrograms();
  },
  computed: {
    ...mapGetters({ openHours: "core/openHours" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ PharmacyData: "pharmacy/pharmacy" }),
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ pharmacyWorkerNumber: "core/pharmacyWorkerNumber" }),
    ...mapGetters({ computerPrograms: "core/computerPrograms" }),
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getOpenHours: "core/getOpenHours" }),
    ...mapActions({ getPharmacy: "pharmacy/getPharmacyById" }),
    ...mapActions({ putPharmacy: "pharmacy/updatePharmacy" }),
    ...mapActions({ getComputerPrograms: "core/getComputerPrograms" }),
    ...mapActions({ getPharmacyWorkerNumber: "core/getPharmacyWorkerNumber" }),
    validateAddress(address, formatted) {
      if (
        this.pharmacy[address].formatted != formatted &&
        this.pharmacy[address].latitude
      ) {
        this.pharmacy[address] = { formatted: formatted };
      }
    },
    updatecountryOwnerTelephone(country) {
      this.countryOwnerTelephone = "+" + country;
    },
    updatecountryContactTelephone(country) {
      this.countryContactTelephone = "+" + country;
    },
    updatecountryPharmacyTelephone(country) {
      this.countryPharmacyTelephone = "+" + country;
    },
    async formatData() {
      Object.assign(this.pharmacy, _.cloneDeep(this.PharmacyData));
      this.pharmacy;
      this.pharmacy.contacts = JSON.parse(
        JSON.stringify(this.pharmacy.contacts)
      );
      if (this.pharmacy.contacts.length == 0) {
        await this.pharmacy.contacts.push({
          full_name: null,
          phone_number: null,
          email: null,
        });
      }
      this.pharmacy.pharmacy_owner = JSON.parse(
        JSON.stringify(this.pharmacy.pharmacy_owner)
      );
      if (this.pharmacy.contacts[0].full_name != null) {
        this.contact = true;
      }
      if (this.pharmacy.fiscal_name == this.pharmacy.commercial_name) {
        this.billing_match_data = true;
      }
      this.pharmacy.open_hour = this.pharmacy.open_hour.id;
      //this.formatPhones();
    },
    updateOwnerPhoneNumber(phone) {
      this.pharmacy.pharmacy_owner.phone_number = phone;
    },
    updateContactPhoneNumber(phone) {
      this.pharmacy.contacts[0].phone_number = phone;
    },
    updatePhoneNumber(phone) {
      this.pharmacy.phone_number = phone;
    },
    phone() {
      this.pharmacy.phone_number = this.country_code.concat(
        "",
        this.phone_number
      );
    },
    /* pharmacy_phone() {
      this.pharmacy.pharmacy_owner.phone_number = this.pharmacy_code.concat(
        "",
        this.pharmacy_phone_number
      );
    }, */
    /* contact_phone() {
      this.pharmacy.contacts[0].phone_number = this.contact_code.concat(
        "",
        this.contact_phone_number
      );
    }, */
    getAddressData(addressData, placeResultData) {
      this.pharmacy.address = AddressDataHelper.addressData(
        addressData,
        placeResultData
      );
    },
    getBillingAddressData(addressData, placeResultData) {
      this.pharmacy.billing_address = AddressDataHelper.addressData(
        addressData,
        placeResultData
      );
    },
    RefactorSendPharmacy() {
      this.pharmacy.pharmacy_owner.pharmacy = this.pharmacy.id;
      if (this.pharmacy.contacts[0].full_name != null) {
        if (this.contact == false) {
          this.pharmacy.contacts = [];
        } else {
          this.pharmacy.contacts.map((c) => {
            c.pharmacy = this.pharmacy.id;
          });
        }
      } else {
        this.pharmacy.contacts = [];
      }
    },
    formatPhones() {
      this.country_code = this.PharmacyData.phone_number.substr(
        0,
        this.PharmacyData.phone_number.length - 9
      );
      this.country_code = this.country_code.concat(" ");
      this.phone_number = this.PharmacyData.phone_number.substr(
        this.PharmacyData.phone_number.length - 9
      );
      // pharmacy number
      this.pharmacy_code = this.PharmacyData.pharmacy_owner.phone_number.substr(
        0,
        this.PharmacyData.pharmacy_owner.phone_number.length - 9
      );
      this.pharmacy_code = this.pharmacy_code.concat(" ");
      this.pharmacy_phone_number =
        this.PharmacyData.pharmacy_owner.phone_number.substr(
          this.PharmacyData.pharmacy_owner.phone_number.length - 9
        );
      // contact phone
      if (this.pharmacy.contacts[0].full_name != null) {
        this.contact_code = this.PharmacyData.contacts[0].phone_number.substr(
          0,
          this.PharmacyData.contacts[0].phone_number.length - 9
        );

        this.contact_code = this.contact_code.concat(" ");
        this.contact_phone_number =
          this.PharmacyData.contacts[0].phone_number.substr(
            this.PharmacyData.contacts[0].phone_number.length - 9
          );
      }
    },
    async updatePharmacy() {
      await this.RefactorSendPharmacy();
      await this.formatOwnerTelephone();
      await this.formatContactTelephone();
      await this.formatPharmacyTelephone();
      await this.enableLoading();
      this.pharmacy.soe = this.pharmacy.soe || null;
      await this.other_program();
      try {
        await this.putPharmacy(this.pharmacy);
        this.$notify({
          title: this.$i18n.t("notification.profile_update"),
          text: this.$i18n.t("notification.profile_update"),
          type: "success",
        });
        this.$router.push({ name: "profile" });
      } catch (error) {
        Object.values(error.response.data).forEach((elm) => {
          this.$notify({
            title: this.$i18n.tc("notification.profile_update_error", 1),
            text: elm.join(","),
            type: "error",
          });
        });
      } finally {
        this.disableLoading();
      }
    },
    formatPhone() {
      //let listPhone = this.phoneNumber.split(" ")
      /* if (this.fields.phone_number.startsWith("+")){
        let listPhone = this.fields.phone_number.split(" ")
        listPhone.shift()
        this.fields.phone_number = listPhone.join("")
      } */
    },
    other_program() {
      if (this.others_programs) {
        this.pharmacy.computer_programs = null;
        this.pharmacy.others_computer_programs =
          this.pharmacy.others_computer_programs || null;
      } else {
        this.pharmacy.computer_programs =
          this.pharmacy.computer_programs || null;
      }
    },
    formatOwnerTelephone() {
      let phone = this.pharmacy.pharmacy_owner.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacy.pharmacy_owner.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.pharmacy_owner.phone_number = country + " " + phone2;
      } else {
        let tel =
          this.countryOwnerTelephone +
          this.pharmacy.pharmacy_owner.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.pharmacy_owner.phone_number = country + " " + phone2;
      }
    },
    formatContactTelephone() {
      if (!this.pharmacy.contacts.length) return;
      let phone = this.pharmacy.contacts[0].phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacy.contacts[0].phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.contacts[0].phone_number = country + " " + phone2;
      } else {
        let tel =
          this.countryContactTelephone + this.pharmacy.contacts[0].phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.contacts[0].phone_number = country + " " + phone2;
      }
    },
    formatPharmacyTelephone() {
      let phone = this.pharmacy.phone_number;
      if (phone.startsWith("+")) {
        let tel = this.pharmacy.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.phone_number = country + " " + phone2;
      } else {
        let tel = this.countryPharmacyTelephone + this.pharmacy.phone_number;
        tel = tel.split(" ");
        let country = tel[0];
        tel.shift();
        let phone = tel.join();
        let phone2 = phone.replaceAll(",", "");
        this.pharmacy.phone_number = country + " " + phone2;
      }
    },
  },
};
</script>
